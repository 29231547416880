import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Em, Link, Box, Section, Strong } from "@quarkly/widgets";
const defaultProps = {
	"background": "--color-dark",
	"padding": "80px 0 80px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"flex-direction": "column",
			"justify-content": "center",
			"padding": "0px 200px 0px 200px",
			"lg-padding": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"color": "--light",
			"text-align": "center",
			"font": "--headline2",
			"children": "Измените свой образ сегодня!"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"text-align": "center",
			"color": "--light",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"font": "--headline5",
			"href": "optixial.com",
			"children": "info@optixial.com"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"text-align": "center",
			"color": "--light",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"font": "--headline5",
			"href": "+77477777791",
			"children": "+77477777791"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"color": "--light",
			"text-align": "center",
			"font": "--headline5",
			"children": "Ulitsa Konstitutsii Kazakhstana 20, Petropavl 150000, Kazakhstan"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"font": "--base",
			"color": "rgba(255, 255, 255, 0.51)",
			"text-align": "center",
			"children": <Em>
				Copyright 2024.
			</Em>
		}
	}
};

const Footer1 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Text {...override("text1")} />
		</Box>
		<Text {...override("text2")} />
		{children}
	</Section>;
};

Object.assign(Footer1, { ...Section,
	defaultProps,
	overrides
});
export default Footer1;